import axios from "axios"

export const OfferApi = {
    async OfferCreatorPriceUpdateAndSendCarrierForAgreement(data){
        const response = await axios.post('/api/offer/offercreatorpriceupdateandsendcarrierforagreement',data)
        return response
    },
    async Create(data){
        const response = await axios.post('/api/offer/create',data)
        return response
    },
    async Get(id){
        const response = await axios.get(`/api/offer/get/${id}`)
        return response
    },
    async CarrierUpdatePrice(data){
        const response = await axios.post(`/api/offer/carrierupdateprice`,data)
        return response
    },
    async Table(data){
        const response = await axios.post(`/api/offer/table`,data)
        return response
    },
    async Decline(id){
        const response = await axios.post(`/api/offer/decline/${id}`)
        return response
    },
    async ConvertToAgreement(id){
        const response = await axios.post(`/api/offer/converttoagreement/${id}`)
        return response
    },
    async UploadExcel(data){
        const response = await axios.post(`/api/offer/Upload/Excel/OfferItems`,data)
        return response
    },
    async DownloadSampleFile(){
        const response = await axios.get(`/api/offer/downloadsamplefile`,{
            responseType: 'blob',
        })
        return response
    },
    async DownloadExcelFile(groupCode){
        const response = await axios.get(`/api/offer/get/excelexport/${groupCode}`,{
            responseType: 'blob',
        })
        return response
    },

}